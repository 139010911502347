@charset "utf-8";
/* CSS Document */
 
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

body {
    margin: 0;
    padding: 0;
    font-family: 'Mulish', sans-serif !important;
    font-size: 14px;
    color: #3a3a3a;
    font-weight: 400; 
    position: relative;
}

/*::-webkit-scrollbar {
  width: 10px;
  height: 8px;
} 
 
::-webkit-scrollbar-track {
  background: #eddf00;
  border-radius: 20px;
}
   
::-webkit-scrollbar-thumb {
  background: #272c36;
  border-radius: 10px;
}*/

* {
    margin: 0px;
    padding: 0px;
}

.clearfix {
    zoom: 1;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
    line-height: 0;
}

.clearfix:after {
    clear: both;
}

.clr,
.clear {
    clear: both;
}

header,
footer,
aside,
article,
section,
detail,
nav,
figure {
    position: relative;
    
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

ul,
li,
ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    outline: none !important;
    cursor: pointer;
}

a:hover,
a:focus {
    text-decoration: none !important;
    outline: none;
}

img {
    border: 0;
    outline: none;
    max-width: 100%;
}

input[type="text"],
input[type="password"],
select,
textarea,
input[type="button"] {
    max-width: 100%;
}

table {  width: 100%; }
p { margin: 0; padding: 0; }
.fltL { float: left; }
.fltR { float: right !important; }
.padding_none { padding: 0 !important;}
.border_none{border:none !important}
.navbar-toggle {  background-color: #fff !important; }
.icon-bar { background-color: #000 !important; }
.p_right0{ padding-right:0px !important }
.p_left0{ padding-left:0px !important } 
input[type=date].form-control{ text-transform: uppercase !important; }
select{cursor: pointer;outline: 0;}


.Header{background-color:#000;padding:20px 0}
.Header .navbar{padding:0}
.Header .navbar .navbar-brand{padding:0;margin:0;width:150px}
.Header .navbar .navbar-collapse{justify-content:end}
.Header .navbar .nav-item{margin:0 0 0 10px}
.Header .navbar .nav-item .nav-link{color:#fff;font-size:14px;font-weight:500;padding:10px 20px;display:flex;align-items:center}
.Header .navbar .nav-item .nav-link span{color:#8293B2;margin:0 5px 0 0}
.Header .navbar .nav-item .nav-link img{margin:0 5px 0 0}
.Header .navbar .nav-item .nav-link select{background:transparent;color:#fff;border:none}
.Header .navbar .nav-item:last-child .nav-link{background:#f2cf00;color:#000;font-weight:600}
   
.SliderArea{position:relative}
.SliderArea .carousel-indicators{width:140px;margin:auto;padding:15px 10px;background-color:#fff}
.SliderArea .carousel-indicators li{width:12px;height:12px;border-radius:50px;border:1px solid #CCC;margin:0 5px;background:#fff;opacity:1}
.SliderArea .carousel-indicators li.active{background-color:#F2CF00;border-color:#F2CF00}
.SliderArea .carousel-item img{width:100%}
.SliderArea .carousel-control-prev,
.SliderArea .carousel-control-next{width:40px;height:40px;background-color:#fff;opacity:1;color:#000;font-size:30px;top:0;bottom:0;margin:auto}
.SliderArea .carousel-control-prev{left:5%}
.SliderArea .carousel-control-next{right:5%}

.SlideContent{position:absolute;top:20%;left:15%;width: 37%;}
.SlideContent h3{color:#fff;font-size:38px;margin:0 0 15px 0;font-weight:700}
.SlideContent p{color:#fff;font-size:18px;font-weight:500;margin:0 0 25px 0}
.SlideContent ul li{display:inline-block;margin:0 5px 0 0}
.SlideContent ul li a{background:#f2cf00;color:#000;font-weight:600;padding:12px 20px;display:block}
.SlideContent ul li a:hover{background-color:#000;color:#f2cf00}

.ServiceArea{padding:50px 0}
.ServiceArea h3{font-size:24px;color:#000;margin:0 0 15px 0;font-weight:600}
.ServiceArea .ServiceBox{border:1px solid #ddd;padding:10px;margin:0 0 30px 0}
.ServiceArea .ServiceBox figure{margin:0}
.ServiceArea .ServiceBox figure img{width:100%}      
.ServiceArea .ServiceBox figcaption{padding:15px}
.ServiceArea .ServiceBox figcaption h4{font-size:15px;font-weight:600;margin:0 0 10px 0;color:#000}
.ServiceArea .ServiceBox figcaption aside{min-height:100px;margin:0 0 15px 0}
.ServiceArea .ServiceBox figcaption p{color:#888;font-size:14px;line-height:24px;display:-webkit-box;-webkit-line-clamp:4;-webkit-box-orient:vertical;overflow:hidden}
.ServiceArea .ServiceBox figcaption a{background:#f2cf00;color:#000;font-weight:600;padding:11px 20px;display:inline-block;width: 49%;text-align:center}
.ServiceArea .ServiceBox article{ display: flex; justify-content: space-between; align-items: center; }
.ServiceArea .ServiceBox:hover{border-color:#f2cf00}
.ServiceArea .ServiceBox:hover a{background-color:#000;color:#f2cf00}
.ServiceArea .Login{background:#f2cf00;color:#000;font-weight:600;padding:11px 80px;display:inline-block;text-align:center}
 
.SubscribeArea{background-color:#000;padding:50px 0;border-bottom:1px solid #444}
.SubscribeArea .SubscribeBox h6{font-size:15px;color:#fff;margin:0 0 25px 0}
.SubscribeArea .SubscribeBox p{color:#8293B2;margin:0 0 15px 0;font-size:14px;font-weight:600}
.SubscribeArea .SubscribeBox form{display:flex}
.SubscribeArea .SubscribeBox form input{border:none;padding:0 15px;width:70%;margin:0 5px 0 0}
.SubscribeArea .SubscribeBox form button{background:#f2cf00;color:#000;font-weight:600;padding:12px 30px;border:none;outline:0;box-shadow:none}
.SubscribeArea .InfoBox{position:relative;padding:0 0 0 30px}
.SubscribeArea .InfoBox .Icon{position:absolute;top:10px;left:0}
.SubscribeArea .InfoBox p{color:#8293B2;margin:0 0 5px 0}
.SubscribeArea .InfoBox h5{font-size:14px;color:#fff;line-height:21px;font-weight:600}

.Copyright{padding:50px 0;background:#000}
.Copyright .CopyBox h4{font-size:17px;color:#eddf00;margin:0 0 25px 0;font-weight:600;}
.Copyright .CopyBox ul li{margin:0 0 15px 0}
.Copyright .CopyBox ul li a{color:#8293B2;margin:0 0 15px 0;font-size:15px;font-weight:400}
.Copyright .CopyBox ul li a:hover{color:#fff}
.Copyright .CopyLabel p{color:#8293B2;font-size:15px;font-weight:400;text-align:center}
.Copyright .CopyLabel ul{float:right}
.Copyright .CopyLabel ul li{display:inline-block;margin:0 0 0 35px}
.Copyright .CopyLabel ul li a{font-size:18px;color:#fff}
.Copyright .CopyLabel ul li a:hover{color:#f2cf00}

.BannerArea{background-image:url(../assets/website/images/Breadcumb.webp);background-size:cover;padding:135px 0;text-align:center}
.BannerArea h3{color:#fff;font-size:45px;font-weight:700;margin:0 0 10px 0}
.BannerArea ul li{display:inline-block;margin:0 15px;position:relative}
.BannerArea ul li+li:before{content:'/';color:#fff;position:absolute;left:-21px}
.BannerArea ul li a{color:#fff;font-size:15px}
.BannerArea ul li.active a{color:#f2cf00}

.BenefitsArea{padding:50px 0;background:#000}
.BenefitsArea .BenefitsLeft{color:#fff}
.BenefitsArea .BenefitsLeft h3{font-size:27px;font-weight:600;margin:0 0 30px 0}
.BenefitsArea .BenefitsLeft h4{font-size:23px;font-weight:700;margin:0 0 15px 0}
.BenefitsArea .BenefitsLeft p{margin:0 0 10px 0;font-size:13px;line-height:22px}
.BenefitsArea .BenefitsRight img{width:100%}

.VideoArea{padding:50px 0}
.VideoArea .VideoBox .VideoHead{position:relative;margin:0 0 40px 0}
.VideoArea .VideoBox .VideoHead img{width:100%}
.VideoArea .VideoBox .VideoHead span{width:50px;position:absolute;height:50px;top:50%;left:50%;transform:translate(-50%,-50%);cursor:pointer}
.VideoArea .VideoBox .VideoBody{text-align:center}
.VideoArea .VideoBox .VideoBody h3{color:#000;font-size:33px;font-weight:700;margin:0 0 40px 0}
.VideoArea .VideoBox .VideoBody p{margin:0 0 50px 0;font-size:15px;font-weight:500;color:#000}
.VideoArea .VideoBox .VideoBody ul li{display:inline-block;margin:0 14px}
.VideoArea .VideoBox .VideoBody ul li a{background:#f2cf00;color:#000;font-weight:600;padding:13px 40px;display:block}
.VideoArea .VideoBox .VideoBody ul li a:hover{background-color:#000;color:#f2cf00}

.AboutArea{padding:50px 0}
.AboutArea .AboutBox figure{margin:0 0 50px 0}
.AboutArea .AboutBox figure img{width:100%}
.AboutArea .AboutBox figcaption{text-align:center}
.AboutArea .AboutBox figcaption p{margin:0 0 15px 0;font-size:15px;font-weight:400;color:#000;line-height:26px}

.FAQArea{padding-bottom:50px}
.FAQArea h3{text-align:center;color:#000;margin:0 0 30px 0;font-weight:700}
.FAQArea .card{border:1px solid #ddd;border-radius:0}
.FAQArea .card+.card{margin:30px 0 0 0}
.FAQArea .card .card-header{border-radius:0;border:none;cursor:pointer;background:transparent;padding:20px 25px}
.FAQArea .card .card-header:before{content:'\f107';font-family:FontAwesome;color:#000;position:absolute;top:15px;right:20px;font-size:20px;font-weight:600}
.FAQArea .card .card-header h4{font-size:16px;color:#000;font-weight:400}
.FAQArea .card .card-body{border-top:1px solid #ddd}
.FAQArea .card .card-body p{font-size:16px;font-weight:400;color:#000;line-height:30px}
 
.AssistanceArea{padding:70px 0}
.AssistanceArea figcaption{text-align:center}
.AssistanceArea figcaption h3{font-size:26px;color:#000;margin:0 0 15px 0;font-weight:600}
.AssistanceArea figcaption p{font-size:15px;font-weight:500;color:#000}
.AssistanceArea figure{margin:30px 0 0 0}

.ContactArea{padding:60px 0;background-color:#000}
.ContactArea h3{text-align:center;margin:0 0 30px 0;color:#fff;font-size:26px;font-weight:600}
.ContactArea .form-group{margin:0 0 20px 0}
.ContactArea .form-group .form-control{border:none;height:auto;padding:15px 20px;border-radius:0;font-size:14px}
.ContactArea .form-group button{background:#f2cf00;color:#000;font-weight:600;padding:12px 50px;border:none;outline:0;box-shadow:none}

.VacanciesArea{padding-bottom:60px}
.VacanciesArea h3{font-size:26px;color:#000;margin:0 0 25px 0;font-weight:600;text-align:center}
.VacanciesArea .VacanciesBox{background-color:#F5F5F5;border:1px solid #919191;padding:30px;display:flex;justify-content:space-between}
.VacanciesArea .VacanciesBox+.VacanciesBox{margin:30px 0 0 0}
.VacanciesArea .VacanciesBox aside p{font-size:15px;margin:0 0 10px 0;color:#5c5c5c;font-weight:500}
.VacanciesArea .VacanciesBox aside h6{color:#000;font-weight:600}

.RegisterArea{background-color:#000;padding:75px 0;border-bottom:1px solid #3e3e3e;border-top:1px solid #3e3e3e}
.RegisterArea .RegisterLinks{margin:0 0 50px 0}
.RegisterArea .RegisterLinks ul{text-align:center}
.RegisterArea .RegisterLinks ul li{display:inline-block;margin:0 40px}
.RegisterArea .RegisterLinks ul li a{margin:0;border:none;color:rgb(255 255 255 / 50%);padding:0 0 10px 0;border-bottom:1px solid #000;font-size:16px;display:block}
.RegisterArea .RegisterLinks ul li.active a{color:#f2cf00;font-weight:700;border-color:#f2cf00}
   
.RegisterForm .form-group{margin:0 0 25px 0}
.RegisterForm .form-group .form-control{height:auto;border:none;outline:0;padding:12px 15px;font-size:15px;border-radius:0;box-shadow:none}
.RegisterForm .form-group .Extn{display:flex}
.RegisterForm .form-group .Extn .form-control:last-child{width:100px;border-left:1px solid #9f9f9f}

.RegisterForm .RegisterButton{text-align:center;margin:15px 0 0 0;width: 100%;}
.RegisterForm .RegisterButton h4{text-align:center;color:rgb(255 255 255 / 70%);font-size:15px;line-height:25px;margin:0 0 45px 0}
.RegisterForm .RegisterButton h4 img{margin:0 10px 0 0}
.RegisterForm .RegisterButton button,
.RegisterForm .RegisterButton .Submit{background:#f2cf00;font-size:16px;text-transform:uppercase;font-weight:600;border:none;padding:13px 45px;color:#000;width:250px;display:inline-block}
.RegisterForm .RegisterButton h5{margin:20px 0 0 0;color:rgb(255 255 255 / 70%);font-weight:400;font-size:16px;text-align:center}
.RegisterForm .RegisterButton h5 a{color:#f2cf00;font-weight:600}
.RegisterForm .RegisterButton h6{margin:15px 0 0 0;color:rgb(255 255 255 / 70%);font-weight:400;font-size:16px;text-align:center}
.RegisterForm .RegisterButton h6 a{color:#f2cf00;font-weight:600}

.RegisterRole{text-align:center}
.RegisterRole h3{font-size:30px;font-weight:600;color:#fff;margin:0 0 25px 0}
.RegisterRole ul{max-width:350px;margin:auto}
.RegisterRole ul li{margin:0 0 30px 0}
.RegisterRole ul li a{background-color:#fff;display:block;padding:15px 20px;color:#626262;font-size:16px;font-weight:500;text-align:left}
.RegisterRole ul li a:hover{background-color:#f2cf00;color:#000}

.StepBox{text-align:center;margin:0 0 50px 0}
.StepBox h3{color:#fff;font-weight:600;font-size:25px;margin:0 0 50px 0}
.StepBox ul li{display:inline-block;width:225px;position:relative}
.StepBox ul li:before{content:'';position:absolute;width:100%;height:2px;border:1px dashed #cecfcf;top:14px;left:107px}
.StepBox ul li:last-child:before{content:none}
.StepBox ul li span{width:30px;height:30px;display:block;background-color:#CECFCF;border-radius:50px;color:#000;line-height:30px;margin:auto;position:relative}
.StepBox ul li label{margin:5px 0 0 0;color:#CECFCF;font-size:14px;font-weight:400}
.StepBox ul li.active:before{border-color:#F2CF00;border-style:solid}
.StepBox ul li.active span{background:#F2CF00}
.StepBox ul li.active label{color:#F2CF00}

.RepairsBox{margin:0 0 31px 0}
.RepairsBox p{margin:0 0 35px 0;color:#CECFCF;font-size:17px;font-weight:400}
.RepairsBox ul li{display:inline-block;position:relative;margin:0 14px 15px 0}
.RepairsBox ul li input{position:absolute;width:100%;height:100%;opacity:0;cursor:pointer}
.RepairsBox ul li span{background-color:#fff;padding:10px 24px;display:block;border-radius:5px;color:#000;font-weight:600}
.RepairsBox ul li input:checked~span{background:#f2cf00}
.RepairsBox ol{display:flex}
.RepairsBox ol li{width:25%}

.GarageInformation{background-color:#242424;padding:100px 150px}
.GarageInformation .InformationBox h4{font-size:16px;color:#f2cf00;margin:0 0 40px 0}
.GarageInformation .InformationBox h4 a{float:right;color:#fff}
.GarageInformation .InformationBox ul li{border-bottom:2px dashed #7e7b7b;padding:0 0 30px 0;margin:0 0 30px 0}
.GarageInformation .InformationBox ul li h6{margin:0 0 15px 0;color:#838383;font-weight:600;font-size:16px}
.GarageInformation .InformationBox ul li p{color:#fff;font-size:15px;font-weight:600}
.GarageInformation .InformationBox address p{color:#fff;font-size:15px;font-weight:400;line-height:24px;margin:0 0 15px 0}
  
.ModalBox .modal-dialog{margin:100px auto}
.WebsiteModal .modal-content{border-radius:0;border:none;background-color:#000}
.ModalBox .modal-dialog button.Close{width:34px;height:34px;color:#000;font-size:24px;line-height:21px;border-radius:50px;border:none;outline:0;position:absolute;right:-17px;top:-17px}

.LoginForm{padding:50px}
.LoginForm h3{text-align:center;color:#fff;font-size:25px;font-weight:600;margin:0 0 15px 0}
.LoginForm h5{text-align:center;color:rgb(255 255 255 / 80%);font-size:14px;line-height:25px;margin:0 0 30px 0}
.LoginForm h5 img{margin:0 10px 0 0}
.LoginForm .nav-tabs{border:none;margin:0 0 30px 0;justify-content:space-between}
.LoginForm .nav-tabs .nav-item:last-child{margin:0}
.LoginForm .nav-tabs .nav-item .nav-link{margin:0;border:none;color:rgb(255 255 255 / 50%);padding:0 0 10px 0;border-bottom:1px solid #000;font-size:16px}
.LoginForm .nav-tabs .nav-item .nav-link.active{color:#f2cf00;background:transparent;font-weight:700;border-color:#f2cf00}
.LoginForm .form-group{margin:0 0 20px 0}
.LoginForm .form-group .form-control{height:auto;border:none;outline:0;padding:10px 15px;font-size:15px;border-radius:0}
.LoginForm .form-group p{text-align:right}
.LoginForm .form-group p a{font-size:16px;color:#fff;font-weight:500}
.LoginForm button{background:#f2cf00;font-size:16px;text-transform:uppercase;font-weight:600;border:none;padding:13px 45px;color:#000}
.LoginForm h6{margin:40px 0 0 0;color:rgb(255 255 255 / 50%);font-weight:400;font-size:14px;text-align:center}
.LoginForm h6 a{color:#f2cf00;font-weight:600}

.ConfirmationBox{text-align:center;padding:50px 20px}
.ConfirmationBox span{display:inline-block;margin:0 0 30px 0}
.ConfirmationBox p{color:#fff;font-weight:600;font-size:15px;line-height:32px}

/*.modal-backdrop{background-color:#fff!important}*/

.RepairsBox .Radio{display:block;position:relative;padding-left:35px;cursor:pointer;font-size:16px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;margin:0;color:#fff;font-weight:600}
.RepairsBox .Radio input{position:absolute;opacity:0;cursor:pointer}
.RepairsBox .Radio .checkmark{position:absolute;top:0;left:0;height:25px;width:25px;background-color:#eee;border-radius:50%}
.RepairsBox .Radio:hover input~.checkmark{background-color:#ccc}
.RepairsBox .Radio input:checked~.checkmark{background-color:#f2cf00}
.RepairsBox .Radio input:checked~.checkmark:after{display:block}
.RepairsBox .Radio .checkmark:after{content:'\f00c ';font-family:FontAwesome;position:absolute;display:none;color:#000;font-size:13px;top:8px;left:6px;line-height:9px}
/* 
.Radio{display:block;position:relative;padding-left: 30px;margin-bottom:12px;cursor:pointer;font-size:22px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
.Radio input[type=radio]{position:absolute;opacity:0;cursor:pointer}
.Radio .checkmark{position:absolute;top:0;left:0;height: 20px;width: 20px;background-color:#fff;border-radius:50%;border:1px solid #d0cfd8}
.Radio:hover input[type=radio]~.checkmark{background-color:#ccc}   
.Radio input[type=radio]:checked~.checkmark{border-color: #0f172a;background-color: #0f172a;}
.Radio .checkmark:after{content:"";position:absolute;display:none}
.Radio input[type=radio]:checked~.checkmark:after{display:block}
.Radio .checkmark:after{width: 10px;height: 10px;border-radius:50%;background:#fff;top:0;left:0;bottom:0;right:0;margin:auto}
        */
.CheckBox{display: inline-block;position:relative;padding-left:33px;cursor:pointer;font-size: 14px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;font-weight:500;color:#2d2d2d;margin: 0;min-height: 20px;}
.CheckBox input{position:absolute;opacity:0;cursor:pointer;height:0;width:0}
.CheckBox .checkmark{position:absolute;top:0;left:0;height: 20px;width: 24px;background-color:transparent;border: 1px solid #0f172a;border-radius:2px;}
.CheckBox:hover input~.checkmark{background: #0f172a;border-color: #0f172a;}
.CheckBox input:checked~.checkmark{background: #0f172a;}
.CheckBox .checkmark:after{content:"";position:absolute;display:none;left:8px;top:2px;width:5px;height:11px;border:solid white;border-width:0 2px 2px 0;transform:rotate(45deg)}
.CheckBox input:checked~.checkmark:after{display:block}

.switch{position:relative;display:inline-block;cursor:pointer;margin:0;padding:2px 0 3px 50px;font-weight:400;font-size:12px;color:#646467;float: right;}
.switch input{opacity:0;width:0;height:0}
.switch .slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#fff;-webkit-transition:0.4s;transition:0.4s;height: 21px;border-radius:50px;width:40px;border: 1px solid #0f172a;}
.switch .slider:before{position:absolute;content:"";height:13px;width:13px;left:3px;bottom:0;top:0;background: #0f172a;-webkit-transition:0.4s;transition:0.4s;border-radius:50%;margin:auto;}
.switch input:checked+.slider{background: #2c3344;border-color: #2c3344;}
.switch input:checked+.slider:before{-webkit-transform:translateX(19px);transform:translateX(19px);background:#fff}

.SideNavBar{height:100vh;position:fixed;width:250px;top:0;padding:10px;overflow:auto;background:#0f172a}
.SideNavBar::-webkit-scrollbar { width: 7px; height: 8px;} 
.SideNavBar::-webkit-scrollbar-track { background: #0f172a; border-radius: 20px;}
.SideNavBar::-webkit-scrollbar-thumb { background: #4a5267; border-radius: 10px;}
.SideNavBar .SideNavHead{margin:10px 0 20px 0;text-align:center}
.SideNavBar .SideNavHead img{width:130px}
.SideNavBar .SideNavMiddle{text-align:center;color:#fff;margin:0 0 15px 0;background-color:#2c3344;padding:15px;border-radius:5px}
.SideNavBar .SideNavMiddle figure{width:100px;height:100px;border-radius:50%;overflow:hidden;margin:0 auto 15px}
.SideNavBar .SideNavMiddle figure img{width:100%;height:100%}
.SideNavBar .SideNavMiddle h6{font-size:16px;font-weight:600;margin:0 0 5px 0}
.SideNavBar .SideNavMiddle p{font-size:14px;color:#c5c0c0;font-weight:500}
.SideNavBar ul{margin:0}
.SideNavBar ul li{margin-bottom:5px}
.SideNavBar ul li a{display:block;cursor:pointer;padding:15px 15px 15px 60px;transition:0.5s all ease-in-out;color:#fff;font-size:14px;text-transform:capitalize;position:relative;border-radius:5px;font-weight:500}
.SideNavBar ul li a span{font-size:15px;display:inline-block;position:absolute;top:15px;left:22px;width:20px;filter:brightness(0) invert(1);opacity:.7}
.SideNavBar ul li.active a span,
.SideNavBar ul li a:hover{opacity:1}
.SideNavBar ul li.active a,
.SideNavBar ul li a:hover{background-color:#2c3344;color:#fff}

.DashboardHeader{box-shadow:0 2px 4px rgba(0,0,0,.08);-webkit-box-shadow:0 2px 4px rgba(0,0,0,.08);position:fixed;left:0;top:0;background-color:#fff;z-index:99;transition:all 0.2s ease;margin-left:250px;width:calc(100% - 250px);text-align:right;height:60px;padding:0 25px}
.DashboardHeader .HeaderToggle{float:left;padding:15px 0;display:none}
.DashboardHeader .HeaderToggle a{display:inline-block;width:30px;color:#000}
.DashboardHeader .Navigation{transition:all 0.2s ease;text-align:right;height:60px}
.DashboardHeader .Navigation .Avater{margin:0 0 0 30px;position:relative;display:inline-block}
.DashboardHeader .Navigation .Avater a{display:flex;align-items:center;font-size:14px;color:#000;padding:13px 0}
.DashboardHeader .Navigation .Avater a figure{width:35px;border-radius:50%;margin:0 15px 0 0;overflow:hidden;height:35px}
.DashboardHeader .Navigation .Avater a figure img{ width: 100%; height: 100%; }
.DashboardHeader .Navigation .Avater ul{position:absolute;background:#fff;box-shadow:0 3px 11px 0 rgb(0 0 0 / 10%);-webkit-transform:perspective(600px) rotateX(-90deg);transform:perspective(600px) rotateX(-90deg);-webkit-transform-origin:0 0 0;transform-origin:0 0 0;right:0;opacity:0;visibility:hidden;-webkit-transition:0.5s;transition:0.5s;z-index:99;top:100%;text-align:left;min-width:200px;display:block;width:auto}
.DashboardHeader .Navigation .Avater ul li{margin:0;border-bottom:1px solid #d2d3d4}
.DashboardHeader .Navigation .Avater ul li:first-child{background-image:linear-gradient(45deg,#0f172a,#2c3344);position:relative;padding:15px 10px 15px 60px;text-align:left;border-bottom:1px solid #fff}
.DashboardHeader .Navigation .Avater ul li figure{width:35px;height:35px;border-radius:50%;position:absolute;top:15px;left:15px;border:2px solid #fff;overflow:hidden}
.DashboardHeader .Navigation .Avater ul li h4{color:#fff;font-size:17px;line-height:18px; min-height: 33px;}
.DashboardHeader .Navigation .Avater ul li h4 span{font-size:11px;font-weight:400}
.DashboardHeader .Navigation .Avater ul li a{color:#4e4e4e;padding:10px 20px;font-weight:400;transition:0.5s all ease-in-out}
.DashboardHeader .Navigation .Avater ul li a span{margin:0 10px 0 0;display:block}
.DashboardHeader .Navigation .Avater ul li a:hover{background:#2c3344;color:#fff}
.DashboardHeader .Navigation .Avater:hover ul{opacity:1;visibility:visible;transform:perspective(600px) rotateX(0deg)}

.WrapperArea{margin:60px 0 0 250px;flex:1 1 auto;width:calc(100% - 250px);display:flex;flex-direction:column;transition:0.5s all ease-in-out}
.WrapperArea .WrapperBox{padding:25px;background-color:#f1f5f9;min-height: calc(100vh - 60px);}
.Small-Wrapper{background-color:#fff;padding:25px;border:1px solid #dcdcdc}
.Small-Wrapper+.Small-Wrapper{ margin-top: 20px; }

.TitleBox{display: flex; align-items: center; justify-content: space-between; margin: 0 0 20px 0;}
.TitleBox h4{margin: 0;font-weight: 600;color: #000;text-transform: capitalize;font-size: 22px;font-family: 'Roboto';}
.TitleBox h5{ margin: 0; font-weight: 600; color: #000; text-transform: capitalize; font-size: 16px; font-family: 'Roboto';}
.TitleBox h5 .switch{ margin-left: 10px; }
.TitleBox .Edit{font-size: 20px; color: #2c3344; line-height: 20px;}
.TitleBox .TitleLink{ background: #f2cf00; color: #000; font-size: 13px; padding: 10px 25px; border-radius: 5px; font-weight: 700; margin: -5px 0 0 0;}
.TitleBox .TitleLink:hover{ background-color: #0f172a; color: #fff; }

.CommonTabs .nav-tabs{border:none;margin:0 0 25px 0}
.CommonTabs .nav-tabs li{margin:0 15px 0 0}
.CommonTabs .nav-tabs li a{background-color: #d7d7d7;padding: 12px 35px;border:none!important;color:#000;font-weight: 600;cursor:pointer;text-transform:capitalize;margin:0;font-size:14px;display:block;border-radius: 50px;}
.CommonTabs .nav-tabs li a.active{background-color: #2c3344;color:#fff}
    
.SearchBox{margin: 0 0 15px 0;display:flex;justify-content: space-between;}
.SearchBox form{margin:0;display:-webkit-box;display:flex;border-radius: 5px;overflow: hidden;}
.SearchBox form input,
.SearchBox form select{margin:0 0 0;border:1px solid #ddd;box-shadow:none;outline:0;padding: 0 10px;height: 40px;min-width: 200px;border-radius: 5px 0px 0px 5px;}
.SearchBox form button{padding:7px 30px;display:inline-block;background-color: #2c3344;margin:0;color:#fff;-webkit-transition:0.5s all ease-in-out;transition:0.5s all ease-in-out;font-size:14px;letter-spacing:.5px;border:none;outline:0;}
.SearchBox form button:hover{background-color: #ca9900;}.SearchBox a{background: #f2cf00;color: #000;font-size:14px;font-weight: 500;padding: 10px 25px;margin: 0 0 0 15px;border-radius: 5px;}
.SearchBox a:hover{background-color:#04bad2}

.TableList{overflow: auto;background-color: #fff;} 
.TableList table{border:1px solid #dcdcdc}
.TableList table tr:nth-child(odd){background-color:#f5f2f2}
.TableList table tr:hover{background:#c7c7c7}
.TableList table tr th{background-color:#dcdcdc;font-weight: 700;text-transform:capitalize;color:#000;font-size:14px;padding:10px 10px}
.TableList table tr td{font-weight: 600;color:#404040;padding:10px 10px}
.TableList table tr td strong{ font-weight: 700; color: #000; }
.TableList table tr td smap{display: block;text-align: right;}   
.TableList table tr td smap a{ color: #247bd5 !important; font-weight: 700; font-size: 13px; }
.TableList table tr td select{ border: 1px solid #ddd; padding: 5px 10px; border-radius: 5px; color: #000; font-size: 14px; font-weight: 700;}
.TableList table tr th:last-child,
.TableList table tr td:last-child{text-align:center;min-width:105px}
.TableList table tr td:last-child a{margin: 0 2px;font-size:14px;display:inline-block}
.TableList table tr td span{padding:4px 10px;color:#fff;border-radius:5px;width:auto;display:block;text-align:center;font-size:12px}
.TableList table tr td .Actions{display: flex;align-items: center;justify-content: center;}

.TableList table tr td a.Green{ color: #37a713; }
.TableList table tr td a.Red{ color: #e21818; }
.TableList table tr td a.Blue{ color: #139bbf; }
.TableList table tr td a.Yellow{ color: #feca05; }
.TableList table tr td a.Pink{color: #fd546d;}

.TableList table tr td span.Green{ background-color: #37a713; }
.TableList table tr td span.Blue{ background-color: #139bbf; }
.TableList table tr td span.Red{ background-color: #bb0f0f; }
.TableList table tr td span.Orange{background-color: #df9919; }

.BookingDetails article{display:flex;justify-content:space-between;margin:0 0 15px 0}
.BookingDetails article aside{padding:0 0 0 0;min-width:290px}
.BookingDetails article aside h4{font-size:20px;font-weight:700;color:#000;margin:0 0 12px 0}
.BookingDetails article aside h4 .Green{color:#37a908}
.BookingDetails article aside p{font-size:14px;font-weight:500;color:#444;line-height:25px;margin:0 0 10px 0}
.BookingDetails article aside p span{font-weight:700;color:#000;display:inline-block;width:200px;position:relative}
.BookingDetails article aside p span:after{content:':';position:absolute;right:10px}
 
.CommentBox{background-color:#f5f5f5;padding:25px;border:1px solid #eaeaea;border-radius:7px}
.CommentBox h2{margin: 0 0 20px;font-weight:600;color:#2a2a2a;font-size: 24px;letter-spacing:.5px;font-family: 'Roboto';}
.CommentBox ul li{position:relative;padding:0 0 0 110px;margin:0 0 35px}
.CommentBox ul li figure { position: absolute; top: 0; left: 0; width: 80px; height: 90px; margin: 0;}
.CommentBox ul li .Accept{ float: right; }
.CommentBox ul li h4,
.CommentBox ul li ol li h4{margin:0 0 7px;font-weight:600;color:#3a3a3a;font-size: 18px;letter-spacing:0;font-family: 'Roboto';}
.CommentBox ul li h5{ color: #dabc06; font-size: 14px; margin: 0 0 6px 0; }
.CommentBox ul li h5 i{ margin: 0 3px 0 0; }
.CommentBox ul li p,
.CommentBox ul li ol li p{font-size: 14px;line-height: 24px;margin:0 0 10px;color:#5d5b5b;font-weight: 600;}
.CommentBox ul li h6,
.CommentBox ul li ol li h6{margin:0 0 0;color:#182a67;font-size: 15px;}
.CommentBox ul li ol{margin:40px 0 0 0}
.CommentBox ul li ol li{padding:0 0 0 90px;margin:0}
.CommentBox ul li ol li+li{margin:40px 0 0 0}
.CommentBox ul li ol li+li:before{content:'';position:absolute;width:2px;height:30px;background-color:#676262;left:45px;top:-37px}
.CommentBox ul li ol li figure{width:75px;height:75px}  

.GaragesForm .form-group h6{ font-size: 18px; font-weight: 700; color: #000; line-height: 31px;}
.GaragesForm .form-group label{ display: block; font-size: 15px; font-weight: 600; color: #000; margin: 0 0 10px 0;}
.GaragesForm .form-group .form-control{ height: auto; padding: 10px 15px; font-size: 15px; font-weight: 500; color: #000; box-shadow: none; outline: 0; border: 1px solid #b2b2b2;}
.GaragesForm .form-group ul { display: flex; flex-wrap: wrap;}
.GaragesForm .form-group ul li{ width: 20%; display: inline-block; margin: 0 0 20px 0;}
.GaragesForm .form-group ul li label{ margin: 0;}

.PannelOne{cursor: pointer;background-color: #e9e9e9;border-radius: 0;padding: 15px 15px 15px 18px;position: relative;display: flex;align-items: center;justify-content: space-between;margin-bottom: 20px;}
.PannelOne h4{font-weight: 500;font-size: 17px;color: #000;}
.PannelOne span{width: 30px;display: inline-block;}

.PannelTwo{margin-top: 20px;}
.PannelTwo a{cursor: pointer;background-color: #e9e9e9;border-radius: 0;padding: 15px 15px 15px 20px;position: relative;display: block;color: #000;font-weight: 500;font-size: 17px;}
.PannelTwo a span{width: 30px;display: inline-block;}

.StaticBoxArea .panel-group{margin:0;}
.StaticBoxArea .panel-group .panel{border: 1px solid #e9e9e9;border-radius:0;}
.StaticBoxArea .panel-group .panel+.panel{margin: 20px 0 0 0;}
.StaticBoxArea .panel-group .panel .panel-heading{cursor:pointer;background-color: #e9e9e9;border-radius:0;padding: 15px 55px 15px 20px;position:relative;display:flex;align-items:center;justify-content:space-between;}
.StaticBoxArea .panel-group .panel .panel-heading:after{content:'\f107';font-family:FontAwesome;color:#000;position:absolute;top:18px;right:20px;font-size:19px;font-weight:600;line-height:14px;transition:0.5s all ease-in-out}
.StaticBoxArea .panel-group .panel .panel-heading[aria-expanded="true"]:after{transform:rotate(180deg)}
.StaticBoxArea .panel-group .panel .panel-heading h4{font-size: 17px;color:#000;}
.StaticBoxArea .panel-group .panel .panel-heading h4 span{ width: 27px; display: inline-block;}
.StaticBoxArea .panel-group .panel .panel-heading a{color:#000;font-size:14px}
.StaticBoxArea .panel-group .panel .panel-body{ padding: 15px; }
.StaticBoxArea .panel-group .panel .panel-body:before{content:none}
.StaticBoxArea .panel-group .panel .panel-body p{font-size:15px;line-height:28px;font-weight:400;color: #404040;}
.StaticBoxArea .panel-group .panel .panel-body p+p{margin-top:15px}

.ProfileBox .ProfileHead{display:flex;align-items:center;margin:0 0 25px 0}
.ProfileBox .ProfileHead figure{margin:0 20px 0 0;width:125px;height:125px;border-radius:50%;overflow:hidden}
.ProfileBox .ProfileHead figure img{width:100%;height:100%}
.ProfileBox .ProfileHead .Edit{font-weight:600;text-decoration:underline;font-size:16px;cursor:pointer}
.ProfileBox .form-group{margin:0 0 25px 0}
.ProfileBox .form-group label{margin:0 0 10px 0;font-size:14px;font-weight:600}
.ProfileBox .form-group .form-control{height:auto;padding:14px 20px;font-size:16px;font-weight:500;box-shadow:none;outline:0;border:2px solid #ddd;border-radius:6px;color:#666}

.CommonForm .form-group{margin:0 0 20px 0}
.CommonForm .form-group .form-group{margin:0 0 0 0}
.CommonForm .form-group label{display:block;color:#000;font-weight:600;font-size:16px;text-transform:capitalize}
.CommonForm .form-group .form-control{height:auto;padding:10px 10px 10px 15px;border:1px solid #b3b3b3;border-radius:5px;box-shadow:none;font-weight:400;font-size:14px}

.Mechanic{background-color:#fff;padding:20px;border:1px solid #dcdcdc;margin:0 0 20px 0;border-radius:5px;display:flex;align-items:center;justify-content:space-between}
.Mechanic .MechanicLeft p{font-weight:800;color:#000;font-size:15px}
.Mechanic .MechanicLeft select{border:none;color:#12910a;font-size:16px;font-weight:700}
.Mechanic .MechanicRight{display:flex;align-items:center}
.Mechanic .MechanicRight span{width:30px;height:30px;background:#0f172a;text-align:center;color:#fff;font-size:20px;line-height:30px;border-radius:50px;cursor:pointer}
.Mechanic .MechanicRight p{font-weight:600;color:#000;margin:0 30px}
.MechanicRight p select{
    border: 1px solid #0000;
    color: #575757;
    color: var(--rs-text-primary);
    left: 0;
    outline: none;
    top: 0;
    padding: 4px 10px 4px 10px;
}


.ScheduleBox{display:flex}
.ScheduleBox aside{width:10%;border-right:1px solid #ddd;padding:0 10px 0 0}
.ScheduleBox aside h4{margin:0 0 20px 0;font-size:18px;font-weight:700; text-align: center;}
.ScheduleBox aside ul li{min-height:70px}
.ScheduleBox aside ul li label{margin:0;font-size:15px;font-weight:700;color:#000}
.ScheduleBox article{width:calc(100% - 10%);display:flex;flex-wrap:wrap}
.ScheduleBox article aside{width:20%;padding:0 10px}
.ScheduleBox article aside ul li{min-height: 70px;background-color: #ddd;padding: 10px 10px;border-radius: 5px;margin: 0 0 20px 0;}
.ScheduleBox article aside ul li span{display:block;text-align:left;font-size:14px;font-weight:600;line-height:21px;margin:0 0 5px 0}
.ScheduleBox article aside ul li a{font-size:14px;line-height:18px;color:#000}

.GaragesBox{background-color:#fff;padding:15px;border-radius:10px;box-shadow:0 0 8px #b9b9b9}
.GaragesBox+.GaragesBox{margin-top:20px}
.GaragesBox .GaragesHead{display:flex;margin:0 0 15px 0}
.GaragesBox .GaragesHead figure{width:275px;margin:0;border-radius:5px;overflow:hidden}
.GaragesBox .GaragesHead figure .Rates{position:absolute;top:10px;left:10px;background-color:#2c3344;padding:8px 10px;border-radius:5px;color:#fff;font-size:13px;font-weight:500;line-height:10px}
.GaragesBox .GaragesHead figure .Rates i{margin:0 0 0 5px}
.GaragesBox .GaragesHead figcaption{width:calc(100% - 275px);padding:0 0 0 15px}
.GaragesBox .GaragesHead figcaption h4{font-size:20px;font-weight:700;color:#000;margin:0 0 30px 0}
.GaragesBox .GaragesHead figcaption h4 a{ color: #000; }
.GaragesBox .GaragesHead figcaption h4 span{float:right;font-size:14px;padding:3px 0}
.GaragesBox .GaragesHead figcaption h5{margin:0;font-size:18px;font-weight:600;color:#000;margin-right: 32px;}
.GaragesBox .GaragesHead article{display:flex;justify-content:space-between;margin:10px 0 0 0}
.GaragesBox .GaragesHead article aside h6{font-size:17px;font-weight:600;color:#000;margin:0 0 5px 0}
.GaragesBox .GaragesHead article aside p{font-size:14px;font-weight:500;color:#444;line-height:25px}
.GaragesBox .GaragesMiddle{display:flex;justify-content:space-between;margin:0 0 15px 0}
.GaragesBox .GaragesMiddle aside p{font-size:15px;font-weight:700;color:#000}
.GaragesBox .GaragesMiddle aside p span{color:#3766d1}
 
.QuoteBox h3{font-size:20px;font-weight:700;color:#000;margin:0 0 7px 0}
.QuoteBox h3 span{float:right;font-size:14px;padding:3px 0}
.QuoteBox .QuotePart{background:#f1f5f9;padding:20px;border-radius:10px;margin-bottom:20px}
.QuoteBox .QuotePart h4{font-size:18px;font-weight:700;color:#000;margin:0 0 10px 0}
.QuoteBox .QuotePart h5{font-size:16px;font-weight:700;color:#000;margin:0 0 10px 0}
.QuoteBox .QuotePart ul{display:flex;flex-wrap:wrap}
.QuoteBox .QuotePart ul li{width:32%;border-radius:5px;border:1px solid #ddd;background-color:#fff;padding:10px 10px 10px 40px;position:relative;margin:0 19px 0 0}
.QuoteBox .QuotePart ul li:nth-child(3n){margin-right:0}
.QuoteBox .QuotePart ul li h6{float:right;font-size:18px;font-weight:800;color:#0baf2e}
.QuoteBox .QuotePart ul li p{font-size:14px;font-weight:500;color:#444;line-height:25px}
.QuoteBox .QuotePart ul li p span{font-weight:700;color:#000}
.QuoteBox .QuotePart ul li .Radio{position:absolute;top:17px;left:10px}
.QuoteBox .QuotePart article{display:flex;margin:20px 0 0 0}
.QuoteBox .QuotePart article p{font-size:15px;font-weight:700;color:#000;min-width:130px;margin:0 40px 0 0}
.QuoteBox .QuotePart article .Radio{font-size:15px;font-weight:700;color:#000;min-width:130px;margin:0 40px 0 0}
.QuoteBox .QuotePart article .Radio .checkmark{top:2px}

.QuoteBox .Cost{display:flex;align-items:center;justify-content:space-between}
.QuoteBox .Cost p{font-size:20px;font-weight:800;color:#000}

.DealerDetails .DealerSlider{position:relative;margin:0 0 34px 0}
.DealerDetails .DealerSlider .Rates{position:absolute;top:10px;left:10px;background-color:#2c3344;padding:8px 10px;border-radius:5px;color:#fff;font-size:13px;font-weight:500;line-height:10px}


.DealerDetails .DealerSlider .carousel-item{
    height: 450px;
}

.DealerDetails .DealerSlider .carousel-item img{
    /* width: 100%; */
    height: 100%;
}


.DealerDetails .GaragesBox{padding:0;box-shadow:none;margin:0}
.DealerDetails .GaragesBox .GaragesHead figcaption{width:100%;padding:0}
.DealerDetails .GaragesBox .QuoteBox{background:transparent;padding:0}
.DealerDetails .GaragesBox .QuoteBox figcaption{margin:0;text-align:left}
.DealerDetails .GaragesBox .QuoteBox figcaption a{margin:0 5px 0 0}
.DealerDetails .PartBox{margin:0}
.DealerDetails .PartBox h3{font-size:22px;font-weight:700;color:#000;margin:0 0 10px 0}
.DealerDetails .PartBox h4{font-size:16px;font-weight:600;color:#000;margin:0 0 15px 0}
.DealerDetails .PartBox h4 span{color:#3766d1}
.DealerDetails .PartBox ul{display:flex;flex-wrap:wrap;margin:0;padding:0 0 0 20px}
.DealerDetails .PartBox ul li{font-size:15px;font-weight:500;color:#000;line-height:21px;width:25%;margin:0 0 15px 0;list-style:disc}
.DealerDetails .PartBox ul li:nth-last-child(-n+4){margin:0}
.DealerDetails .PaymentBox h4{font-size:22px;font-weight:700;color:#000;margin:0 0 15px 0}
.DealerDetails .PaymentBox ul{display:flex;flex-wrap:wrap}
.DealerDetails .PaymentBox ul li{width:33.33%;padding:0 15px 0 0}
.DealerDetails .PaymentBox ul li a{background-color:#f5f5f5;padding:15px 15px;border:1px solid #eaeaea;border-radius:7px;display:block;color:#000;font-size:16px;font-weight:600}
 

.EditNext{margin:30px 5px}
.EditNext a.Edit{font-size:16px;font-weight:500;height:45px;transition:0.5s all ease-in-out;margin-right:20px;color:#2c3344;padding:10px;border-radius:6px;border:1px solid #2c3344}
.EditNext a.Edit i{margin-left:10px}


.Delivery{margin-bottom:20px}.Delivery h5{font-weight:700;padding:0 0 10px;font-size:15px;color:#000}
.Category .MainPart h3{text-align:left}
.MainPart .form-group label{display:block;font-weight:500;color:#a19c9c;font-size:15px;font-family:"Roboto"}.Add{text-align:right;margin:10px}
.MainPart .CheckboxMain{display:flex;align-items:center;margin-bottom:40px}
.MainPart .CheckboxMain label{margin-right:20px}



.CongratulationsBox.Welcome{min-height:420px;text-align:center;padding:110px 20px 80px 20px}
.CongratulationsBox.Welcome h4{margin-bottom:10px;color:#000;font-size:30px}
.CongratulationsBox.Welcome p{font-size:17px;color:grey;margin-bottom:50px}
.CongratulationsBox.Welcome ul{display:flex;align-items:center;justify-content:center}
.CongratulationsBox.Welcome ul li{padding:0 10px}
.CongratulationsBox.Welcome ul li a:hover{background-color:#2c3344;color:#fff}
.CongratulationsBox.Welcome ul li a{background-color:#d7d7d7;padding:15px;border:none!important;color:#000;font-weight:600;cursor:pointer;text-transform:capitalize;margin:0;font-size:14px;display:block;border-radius:11px}

.ComingSoon{background:#2c3344;height:540px;display:flex;align-items:center;justify-content:center;border-radius:10px}
.ComingSoon h1{font-weight:700;font-size:70px;color:#fff}

.GaragesBox{background-color:#fff;padding:15px;border-radius:10px;box-shadow:0 0 8px #b9b9b9}
.GaragesBox+.GaragesBox{margin-top:20px}
.GaragesBox .GaragesHead{display:flex;margin:0 0 15px 0}
.GaragesBox .GaragesHead figure{width:275px;margin:0;border-radius:5px;overflow:hidden}
.GaragesBox .GaragesHead figure .Rates{position:absolute;top:10px;left:10px;background-color:#2c3344;padding:8px 10px;border-radius:5px;color:#fff;font-size:13px;font-weight:500;line-height:10px}
.GaragesBox .GaragesHead figure .Rates i{margin:0 0 0 5px}
.GaragesBox .GaragesHead figcaption{width:calc(100% - 275px);padding:0 0 0 15px}
.GaragesBox .GaragesHead figcaption h4{font-size:20px;font-weight:700;color:#000;margin:0 0 30px 0}
.GaragesBox .GaragesHead figcaption h4 a{ color: #000; }
.GaragesBox .GaragesHead figcaption h4 span{float:right;font-size:14px;padding:3px 0}
.GaragesBox .GaragesHead figcaption h5 { margin-right: 32px; font-size: 18px; font-weight: 600; color: #000;}
.GaragesBox .GaragesHead figcaption h5 span {font-weight: 300;}
.GaragesBox .GaragesHead article{display:flex;justify-content:space-between;margin:10px 0 0 0}
.GaragesBox .GaragesHead article aside h6{font-size:17px;font-weight:600;color:#000;margin:0 0 5px 0}
.GaragesBox .GaragesHead article aside p{font-size:14px;font-weight:500;color:#444;line-height:25px}
.GaragesBox .GaragesMiddle{display:flex;justify-content:space-between;margin:0 0 15px 0}
.GaragesBox .GaragesMiddle aside p{font-size:15px;font-weight:700;color:#000}
.GaragesBox .GaragesMiddle aside p span{color:#3766d1}
.GaragesBox .QuoteBox{background:#f1f5f9;padding:15px;border-radius:10px}
.GaragesBox .QuoteBox h4{font-size:20px;font-weight:700;color:#000;margin:0 0 15px 0}
.GaragesBox .QuoteBox h4 span{float:right;font-size:14px;padding:3px 0}
.GaragesBox .QuoteBox blockquote{display:flex;margin:0}
.GaragesBox .QuoteBox blockquote+blockquote{margin:35px 0 0 0}
.GaragesBox .QuoteBox blockquote aside{width:300px}
.GaragesBox .QuoteBox blockquote aside h5{font-size:18px;font-weight:700;color:#000;margin:0 0 10px 0}
.GaragesBox .QuoteBox blockquote aside p{color:#3766d1;font-size:16px;font-weight:700}
.GaragesBox .QuoteBox blockquote aside ul{padding:0 0 0 15px}
.GaragesBox .QuoteBox blockquote aside ul li{font-size:15px;font-weight:600;color:#000;margin:0 0 10px 0}
.GaragesBox .QuoteBox blockquote aside ul li span{color:#3766d1}
.GaragesBox .QuoteBox blockquote aside a.Rates{    border: none; padding: 10px 30px; text-align: center; transition: 0.5s all ease-in-out; font-weight: 400; display: inline-block; position: relative; font-size: 15px; color: #fff; text-transform: capitalize; letter-spacing: .5px; border-radius: 5px; outline: 0!important; box-shadow: none; font-family: 'Roboto'; background-color: #c8ac08; margin: 10px 0 0 0;}
.GaragesBox .QuoteBox blockquote article h5{font-size:18px;font-weight:700;color:#000;margin:0 0 15px 0}
.GaragesBox .QuoteBox blockquote article ul li label{font-size:15px;font-weight:600;color:#000; margin: 0 0 20px 0;}
.GaragesBox .QuoteBox blockquote article ul li label .Times{margin:10px 0 0 0}
.GaragesBox .QuoteBox blockquote article ul li label .Times input{border:1px solid #ddd;padding:10px 10px;border-radius:5px;min-width:200px;margin:0 15px 0 0;text-transform:uppercase;font-weight:600;font-size:15px; position: relative; opacity: 1;}
.GaragesBox .QuoteBox figcaption{text-align:center;margin:20px 0 10px 0}
.GaragesBox .QuoteBox figcaption a{margin:0 5px}

.GaragesBox .QuoteBox blockquote article ul li label.Radio .checkmark{ background-color: #fff; }

.GaragesBox .QuoteBox blockquote article ul li label.Radio input:checked~.checkmark{ background-color: #f2cf00; }

.viewRequest .modal-dialog {
    max-width: 650px;
}












.Category .form-group .SlotBox.NewSlotBox{
    display: block;
}

.Category .form-group .SlotBox.NewSlotBox label{}

.Category .form-group .SlotBox.NewSlotBox aside{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.Category .form-group .SlotBox.NewSlotBox aside input,
.Category .form-group .SlotBox.NewSlotBox aside .rc-time-picker{ width: 48%; }

.Category .form-group .SlotBox.NewSlotBox aside .rc-time-picker input{ width: 100%; }





.ModalBox .modal-dialog{border:none;max-width:600px}
.modal-dialog { max-width: 450px; margin: 50px auto;}
.Close{position:absolute;top:-13px;right:-13px;width:26px;height:26px;background-color:#fff;border:2px solid rgba(0,0,0,.5);text-align:center;color:#000;font-size:15px;border-radius:50%}

.ServiceBox{padding:30px 25px 15px} 
.ServiceBox h4{text-align:center;font-size:23px;font-weight:700;color: #308ed1;margin:0 0 20px 0;line-height:35px}
.ServiceBox p{font-size:15px;color:#000;line-height:25px;margin:0 0 15px 0}
.ServiceBox p span{font-weight:700;color:#000;display:inline-block;width:200px;position:relative}
.ServiceBox p span:before{content:':';position:absolute;right:10px}

.Category{padding:10px 10px} 
.Category h3{text-align:center;font-weight: 700;margin: 0 0 15px 0;border-bottom:1px solid #ddd;padding: 0 0 10px;font-size: 20px;color: #000;}
.Category .form-group{margin:0 0 20px;position:relative}
.Category .form-group label{display:block;font-weight:500;color:#000;font-size:15px;font-family:"Roboto"}
.Category .form-group .form-control{box-shadow:none;border:1px solid #c3c3c3;height:40px;font-size:14px;color:#000;line-height:22px}
.Category .form-group textarea.form-control{height:auto}
.Category .form-group h4{display:block;font-weight:500;color:#000;font-size:17px;font-family:"Roboto";margin:0 0 10px 0}
.Category .form-group .SlotBox{background-color:#ededed;padding:15px;border-radius:5px; display:flex;justify-content:space-between}
.Category .form-group .SlotBox+.SlotBox{margin-top:15px}
.Category .form-group .SlotBox aside {width:48%}
.Category .form-group ul.Parts li{display:inline-flex;margin:0 0px 10px 0; width: 100%;}
.Category .form-group ul.Parts li input{ margin: 0 10px 0 0; }
.Category .form-group ul.Parts li .Delete{ color: red; font-size: 26px; margin: 0 0 0 10px;}
.Category .form-group ul.Parts li span{background:#ddd;font-size:15px;display:block;color:#000;font-weight:700;padding:10px 30px;border-radius:5px}
.Category .form-group ul.Parts li a{color:#000;font-weight:700;color:#000;font-size:16px}

.CongratulationsBox{padding:30px 25px 15px}
.CongratulationsBox figure{text-align:center;margin:0 0 20px 0}
.CongratulationsBox figure img{max-width:100px}
.CongratulationsBox h4{text-align:center;font-size:23px;font-weight:700;color:#01a601;margin:0 0 20px 0;line-height:35px}
.CongratulationsBox p{font-size:15px;color:#000;line-height:25px;margin:0 0 15px 0}
.CongratulationsBox p span{font-weight:700;color:#000;display:inline-block;width:200px;position:relative}
.CongratulationsBox p span:before{content:':';position:absolute;right:10px}
 
.DeclineBox{padding:30px}
.DeclineBox figure{text-align:center;margin:0 0 20px 0}
.DeclineBox figure img{max-width:100px}
.DeclineBox h4{text-align:center;font-size:20px;font-weight:700;color:#f30001;margin:0 0 20px 0;line-height:30px}
.DeclineBox h5{font-size: 18px;font-weight:700;color: #000000;margin: 0 0 10px 0;line-height:30px}
.DeclineBox .form-group label{display:block;font-size: 15px;color:#000;font-weight:600}
.DeclineBox .form-group .form-control{padding: 10px 15px;box-shadow:none;border:1px solid #ddd;outline:0;height: auto;}
.DeclineBox button{border:none;padding:10px 30px;text-align:center;transition:0.5s all ease-in-out;font-weight:500;display:inline-block;position:relative;font-size:15px;color:#fff;text-transform:capitalize;letter-spacing:.5px;border-radius:5px;outline:0!important;box-shadow:none;font-family:'Roboto';background-color:#d93714}

.DeclineText{margin:0}
.DeclineText h3{font-family:"Roboto";font-weight:700;font-size:22px;margin:0 0 5px;text-align:left}
.DeclineText p{font-size:16px;font-family:"Roboto";color:#777;text-align:left}
.DeclineText h4{margin:15px 0 0 0;text-align:right}
.DeclineText h4 a{text-transform:capitalize;margin:0 0 0 30px;font-size:17px;color:#49a0d5;font-weight:400;-webkit-transition:0.5s all ease-in-out;transition:0.5s all ease-in-out}
.DeclineText h4 a:hover{color:#000}

.QuoteDetailsBox{ background: #f4faff; padding: 15px; border-radius: 10px; border: 1px solid #d3e3f1; margin-bottom: 20px;} 
.QuoteDetailsBox h4{ font-size: 18px;  font-weight: 700; color: #000; margin: 0 0 15px 0;}
.QuoteDetailsBox h4 span{ float: right;  font-size: 13px;}
.QuoteDetailsBox p{font-size:15px;color:#000;line-height:25px;margin:0 0 15px 0}
.QuoteDetailsBox p span{font-weight:700;color:#000;display:inline-block;width:200px;position:relative}
.QuoteDetailsBox p span:before{content:':';position:absolute;right:10px}

.Okay{border:none;padding:10px 25px;text-align:center;transition:0.5s all ease-in-out;font-weight:500;display:inline-block;position:relative;font-size:16px;color:#fff;text-transform:capitalize;border-radius:5px;box-shadow:none;font-family:'Roboto';background-color:#308ed1;margin:20px 0 10px 0}
.Okay:hover{background-color: #2880bf;color: #fff;}

.Accept{border:none;padding: 10px 15px;text-align:center;transition:0.5s all ease-in-out;font-weight:500;display:inline-block;position:relative;font-size:15px;color:#fff;text-transform:capitalize;letter-spacing: 0;border-radius:5px;outline:0!important;box-shadow:none;font-family:'Roboto';background-color: #1fa831 !important; margin: 0 3px; }
.Accept:hover{background-color: #0bb221 !important;color: #fff !important;}

.views{border:none;padding: 10px 15px;text-align:center;transition:0.5s all ease-in-out;font-weight:500;display:inline-block;position:relative;font-size:15px;color:#fff;text-transform:capitalize;letter-spacing: 0;border-radius:5px;outline:0!important;box-shadow:none;font-family:'Roboto';background-color: #f8e809 !important; margin: 0 3px; }
.views:hover{background-color: #f8e809 !important;color: #fff !important;}

.Decline{border:none;padding: 10px 15px;text-align:center;transition:0.5s all ease-in-out;font-weight:500;display:inline-block;position:relative;font-size:15px;color:#fff;text-transform:capitalize;letter-spacing: 0;border-radius:5px;outline:0!important;box-shadow:none;font-family:'Roboto';background-color:#d93714; margin: 0 3px;}
.Decline:hover{background-color: #c92d0b !important;color: #fff !important;}

.ProfileEdit{ position: relative; }

.ProfileEdit input{ position: absolute; top: 0; left: 0; width: 100%; height: 100%; cursor: pointer; opacity: 0; }

.ImaesGroup{display:flex;flex-wrap:wrap;margin:40px 0 0 0}
.ImaesGroup li{width:24%;margin:0 25px 20px 0;position:relative}
.ImaesGroup li:last-child{margin:0 0 20px 0;width:20%}
.ImaesGroup li .Close{width:34px;height:34px;color:#000;font-size:24px;line-height:21px;border-radius:50px;border:none;outline:0;position:absolute;right:-17px;top:-17px}
.ImaesGroup li .AddImages{border:2px dashed #cecfcf;text-align:center;position:relative;padding:75px 0}
.ImaesGroup li .AddImages span{width:30px;height:30px;background:#cecfcf;display:inline-block;border-radius:50px;color:#000;line-height:30px}
.ImaesGroup li .AddImages p{margin:10px 0 0 0;color:#cecfcf}
.ImaesGroup li .AddImages input{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;cursor:pointer}

.GaragesBox .GaragesHead figcaption article aside .Decline{ margin-left: 10px; }

.Category .form-group .SlotBox aside .rc-time-picker-input{width: 100%;min-height: 40px;}

.parts-details_append {
    background: #f1f1f1;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
}

.Parts_Add{
    margin-bottom: 20px;
}

.Parts_Add button{
    background: #0f172a;
    border: none;
    padding: 10px 20px;
    font-weight: 700;
    color: #fff;
    font-size: 15px;
    border-radius: 5px;
}

.Parts_remove{
    background: #c91111;
    border: none;
    width: 40px;
    height: 40px;
    font-size: 20px;
    color: #fff;
    border-radius: 5px;
    position: absolute;
    right: 20px;
    bottom: 20px;
}



.SideNavBar ul li .dropdown-toggle{
    display: block;
    cursor: pointer;
    padding: 15px 10px 15px 60px;
    transition: 0.5s all ease-in-out;
    color: #fff;
    font-size: 13px;
    text-transform: capitalize;
    position: relative;
    border-radius: 5px;
    font-weight: 500;
    width: 100%;
    text-align: left;
    background: transparent !important;
    border: none;
    outline: none !important;
    box-shadow: none !important;
}


.SideNavBar ul li .dropdown-toggle:after{
    position: absolute;
    right: 0;
    top: 25px;
}

.SideNavBar ul li .dropdown-toggle span {
    font-size: 15px;
    display: inline-block;
    position: absolute;
    top: 15px;
    left: 22px;
    width: 20px;
    filter: brightness(0) invert(1);
    opacity: .7;
}

.SideNavBar ul li .dropdown-menu{
    margin: 0;
    background: transparent;
    position: relative !important;
    transform: translate(0) !important;
    float: none;
}

.QuotePartBoxx {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.QuotePartBoxx .QuotePart{ width: 48%; margin: 0; }

.QuoteBox .QuotePartBoxx .QuotePart ul li {
    width: 100%; 
    margin: 0 0 0px 0;
}














.goog-te-combo {
    margin: 13px 0!important;
    border: 1px solid #ddd;
    padding: 8px 10px;
    border-radius: 5px;
    outline: 0;
    cursor: pointer;
}
.goog-te-gadget {
    font-family: roboto;
    font-size: 11px;
    color: #666;
    white-space: nowrap;
    text-align: right;
    height: 32px;
}


.goog-te-gadget-simple{
    background: transparent !important;
    border: none !important;
}

.goog-te-gadget-icon{ display: none;  }


.goog-te-menu-value{
    background-color: #fff;
    padding: 5px 15px;
    display: block;
    border-radius: 3px;
    font-size: 14px;
    margin: 0px 0;
}
.garageViewProfile a{
    background: #eddf00;
    padding: 5px;
    font-size: 12px;
    margin-left: 10px;
    box-shadow: 1px 1px 0px 0px;
}
.reviewButtonView{
    margin-left: 20px;
    background: #f7b450;
    padding: 12px 20px;
    font-size: 14px;
    color: #000;
    border-radius: 5px;
    font-weight: 500;
    letter-spacing: 0;
}

.reviewButtonView i {
    margin-right: 5px;;
}

.SliderArea:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0000007d;
    z-index: 1;
}

.SliderArea .SlideContent{
    z-index: 2;
}

.PanelModal.PanelGallery .modal-dialog{
    max-width: 700px;
}
.PanelModal.PanelGallery .ImaesGroup li .AddImages {
    padding: 40px 0;
}
.views.markerlocation{background-color: #100f0a !important;}
.views.markerlocation:active{background-color: #100f0a !important;}


.AvailabilitySlot{

    background-color: #ededed;
    padding: 15px;
    border-radius: 5px;
}

.AvailabilitySlot h5{
    display: block;
    font-weight: 500;
    color: #000;
    font-size: 15px;
    font-family: "Roboto";
}

.AvailabilitySlot article{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #b5b5b5;
    padding: 10px 0 0 0;
    margin: 10px 0 0 0;
}

.AvailabilitySlot aside{}

.AvailabilitySlot aside label{
    display: block;
    font-weight: 400;
    color: #000;
    font-size: 13px;
    font-family: "Roboto";
}

.AvailabilitySlot aside .form-control{
    box-shadow: none;
    border: 1px solid #c3c3c3;
    height: 40px;
    font-size: 14px;
    color: #000;
    line-height: 22px;
}

.NewScheduleBox{}

.NewScheduleBox .NewScheduleHead{
    display: flex;
}

.NewScheduleBox .NewScheduleHead .NewScheduleTime{
    width: 10%;
    border-right: 1px solid #ddd;
    padding: 0 10px 0 0;
}

.NewScheduleBox .NewScheduleHead .NewScheduleDate{
    display: flex;
    width: 90%;
}

.NewScheduleBox .NewScheduleHead .NewScheduleDate aside{
    width: 20%;
    padding: 0 10px 15px;
    border-right: 1px solid #ddd;
}

.NewScheduleBox .NewScheduleHead .NewScheduleDate aside h4{
    font-size: 18px;
    font-weight: 700;
    text-align: center;
}

.NewScheduleBox .NewScheduleBody{}

.NewScheduleBox .NewScheduleBody ul{
    display: flex;
}

.NewScheduleBox .NewScheduleBody ul li{
    width: 17%;
    padding: 0 10px;
    text-align: center;
}

.NewScheduleBox .NewScheduleBody ul li:first-child{
    width: 11%;
}

.NewScheduleBox .NewScheduleBody ul li:first-child label{
    margin: 0;
    font-size: 15px;
    font-weight: 700;
    color: #000;
}


.NewScheduleBox .NewScheduleBody ul li aside{
    min-height: 70px;
    background-color: #ddd;
    padding: 10px 10px;
    border-radius: 5px;
    margin: 0 0 10px 0;
    text-align: left;
}


.NewScheduleBox .NewScheduleBody ul li aside span{
    display: block;
    font-weight: 600;
    color: #000;
    line-height: 24px;
}

.NewScheduleBox .NewScheduleBody ul li aside a{
    font-size: 12px;
    float: right;
}

.week_filterBox {
    padding: 7px 31px 12px 21px;
    border: 1px solid #dcdcdc;
    margin: 0 0 13px 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.LanguageSelect{ display: inline-block; }

.LanguageSelect a{ color: #000; }

.LanguageSelect select{
    border: 1px solid #ddd;
    background-color: transparent;
    margin: 0 0 0 10px;
    padding: 4px 6px;
    border-radius: 5px;
}

.LoginButton{}


.Copyright .CopyBox ul li.LoginButton a{
    background: #f2cf00;
    color: #000;
    font-weight: 600;
    padding: 10px 0px;
    border: none;
    box-shadow: none;
    display: inline-block;
    margin: 0;
    min-width: 120px;
    text-align: center;
    border-radius: 5px;
}

.SearchBox .Filter .form-group{ margin: 0 10px 0 0; }
.SearchBox .Filter .form-group label{ display: block; }


.ImageModal .modal-dialog{ max-width: 900px; }

.ImageModal .CongratulationsBox{ padding: 0; }



.ImageModal .CongratulationsBox .ImaesGroup li .AddImages{ padding: 30px 0; }

.ServiceImages{}

.ServiceImages ul{ margin: 0; }

.ServiceImages ul li{ display: inline-block; width: 150px; margin: 0 15px 5px 0; border: 2px solid #ddd; border-radius: 5px; overflow: hidden;  }

.ServiceImages ul li img{}


.MediumModal .modal-dialog{
    max-width: 550px;
}

.AvailabilitySlot ul{}

.AvailabilitySlot ul li{
    display: inline-block;
    width: 50%;
    margin: 0 0 10px 0;
}

.AvailabilitySlot ul li label{
    display: inline-block !important;
}

.VehicleEvent{
    text-align: center;
}

.VehicleEvent p{
    text-align: left;
    margin-bottom: 0px;
    color: #000;
}

.VehicleEvent a{
    display: inline-block;
    color: #000;
    font-weight: 600;
    text-transform: capitalize;
    text-decoration: underline;
}

.fc-v-event{
    background: #96bce1 !important;
    border-color: #96bce1 !important
} 

.svp_spain_flag{
        height: 15px;
        width: 28px;
      }

.Pagination{margin:20px 0 0 0;display:flex;justify-content:end;font-family: 'Roboto';align-items:center;}
.Pagination ul.pagination li{display:inline-block;margin:0 4px}
.Pagination ul.pagination li a{font-size:15px;font-weight:500;color:#3e3e3e}
.Pagination ul.pagination li.active a{color: #fd546d;} 

.CommonTabs .nav-tabs li.ActiveTab .nav-link{
    background-color: #f2cf00;
}


.slider_text_hide_mvp{
    display: block;
}

.header_lvp_section{
    display: block;
}

.header_svp_section{
    display: none;
}

.need_help{
    display: none;
}

.img_new_car_showroom{
    height: 218.933px;
}

.mvpMenuBar{
    display: none;
}

.SideNavBar{
    display: block;
}

.mvpCloseSidebarBtn{
    display: none;
}

@media screen and (max-width: 768px) {

    .SlideContent h3 {
        font-size: 18px;
        line-height: 25px;
        margin: 0 0 3px 0;
    }

    .SlideContent{
        position: absolute;
        top: 0%;
        left: 3%;
        width: 100%;

    }

    .SlideContent p {
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        margin: 0 0 5px 0;
        width: 95%;
    }

    .need_help{
        display: block;
    }
    .need_help h3 {
        font-size: 24px;
        color: #000;
        margin: 15px 0  15px 15px;
        font-weight: 600;
        line-height: 30px;
        display: block;
    }

    .need_help_btn_group{
        display: flex;
        gap: 5px;
        margin: 15px;
        flex-direction: row;
        justify-content: flex-start;
    }

    .need_help_btn {
        background: #f2cf00;
        padding: 15px 10px;
        width: 100%;
    }

    .need_help_btn a {
        color: #000;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        }

    .ServiceArea{
        padding-top: 10px
    }

    .slider_text_hide_mvp{
        display: none;
    }

    .navbar_brand_title {
        padding: 0;
        margin: 0;
        width: 150px;
        display: inline-block;
        margin-right: 1rem;
        font-size: 1.25rem;
        line-height: inherit;
        white-space: nowrap;
    }

    .header_lvp_section{
        display: none;
    }

    .header_svp_section{
        display: block;
    }

    .menu-icon {
        display: block;
        cursor: pointer;
      }

      .nav-elements {
        position: absolute;
        right: 0;
        top: 57px;
        background-color: #ffffff;
        width: 0px;
        transition: all 0.3s ease-in;
        overflow: hidden;
        z-index: 999;
        margin-right:-15px
      }

      .nav-elements.active {
        width: 187px;
      }
      .svp_flag_btn {
        display: flex;
        flex-direction: row;
        gap: 5px;
      }
      .hr_ruler_line {
        margin: 10px;
      }

      .modal-dialog button.Close {
        top: 0px;
        right: 0px;
      }

      .svp_pad_lang {
        padding-right: 10px;
      }

      .SliderArea .carousel-indicators {
        background-color: transparent;
      }

      .svg_lang_font {
        font-size: 16px;
        margin-top: 10px;
        font-weight: 600;
      }

      .img_new_car_showroom{
        height: 241.867px;
    }

    .ServiceArea .ServiceBox figcaption aside {
        min-height:initial;
    }

    .need_help h6 {
        margin: 15px 0 15px 15px;
      }

      .ServiceArea {
        padding-bottom: 0px;
      }

      .svp_footer_width .col-sm-3{
        width: auto;
      }

      .svp_footer_width .col-sm-2{
        width: auto;
      }

      .Copyright{
        padding-top: 25px;
      }

      .Copyright .CopyBox h4 {
        margin: 0px 0 15px 0;
      }

      .CopyLabel{
        margin-left: -65px;
      }

      .SubscribeArea .SubscribeBox h6 {
        margin: 20px 0 25px 0;
      }

      .svp_subscribe_box .col-sm-6{
        width: auto;
      }

      .SubscribeArea {
        padding: 15px 0;
      }

      .svp_lang_select_container{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        gap: 10px;
      }

      .svp_flag{
        gap: 3px;
        display: flex;
      }

      .ServiceArea .ServiceBox figcaption h4 {
        font-size: 18px;
      }

      /* .container {
        margin-top: -1px;
        padding: 0 0 0 10px !important;
      } */
      .Header .navbar .navbar-brand {
        width: 115px;
      }

      .Copyright .CopyLabel p {
        margin-left: 50px;
      }

      .Copyright .CopyLabel ul {
        margin-right: 15px;
      }

      .SubscribeArea .SubscribeBox form button {
        margin-right: 10px;
      }

      .svp_spain_flag{
        height: 15px;
        width: 28px;
      }

      .VideoBox video{
        max-width: 100%;
        height: auto;
      }

      /* .VideoArea{
        padding: 30px 0;
      } */

      .VideoArea .VideoBox .VideoBody ul{
        display: flex;
        justify-content: center;
        flex-direction: row;
        gap: 18px;
      }

      .VideoArea .VideoBox .VideoBody ul li a {
        padding: 20px 10px;
      }

      .VideoArea .VideoBox .VideoBody ul li{
        margin: 0px;
        width: 180px;
        display: inline-block;
      }

      .VideoArea {
        padding: 30px 0;
      }

      .VideoArea .VideoBox .VideoBody p {
        margin: 0px 0 30px 0;
      }

      .VideoArea .VideoBox .VideoBody h3 {
        margin: 20px 0 20px 0;
      }

      .GarageInformation{
        padding: 100px 75px;
      }

      .StepBox ul li::before {
        left: 40px;
      }

      .StepBox ul li{
        width: 110px;
      }

      .RegisterForm .form-group{
        margin: 0px 0 5px 0;
    }
     .RegisterForm .form-group .ImaesGroup li .AddImages {
        width: 200px;
        margin-left: 80px;
    }

    .RegisterForm .form-group .ImaesGroup {
        flex-direction: column;
    }

    .RegisterForm .form-group .ImaesGroup li:nth-child(n+2):nth-child(-n+6) {
        margin-left: 80px;
        width: 200px !important;
      }
      .RepairsBox p{
        margin: 0 17px 35px 50px;
      }

      .RepairsBox ul{
        margin-left: 50px;
      }

      /*Garage Dashboard */
      /* .DashboardHeader{
          margin-left: 150px;
      } */

      .WrapperArea {
        margin: 0px;
        flex: 1 1 auto;
        width: calc(100% - 0px);
        display: flex;
        flex-direction: column;
        transition: 0.5s all ease-in-out;
      }

     .Close {
        top: 0px;
        right: 0px;
        width: 32px;
        height: 32px;
    }

    .openBtnSidebar {
        width: 55px;
        font-size: 28px;
        height: 50px;
    }

    .LanguageSelect a {
        padding: .5rem .5rem;
    }

    .SideNavBar{
        z-index: 999;
    }

     .DashboardHeader {
        margin-left: 0px;
        text-align: left;
        height: 50px;
        width: 100%;
        padding: 0px;
        display: flex;
    } 

/* Client section*/

    .DashboardHeader .Navigation .Avater {
        margin: 0px;
    }

    .DashboardHeader .Navigation{
        text-align: left;
        height: 50px;
    }

    .DashboardHeader .Navigation .Avater a figure {
        width: 30px;
        border-radius: 50%;
        margin: 0 5px 0 0;
        overflow: hidden;
        height: 30px;
    }


    .CommonTabs .nav-tabs li {
        margin: 0px 10px 10px 0px;
    }

    .GaragesForm .form-group ul li {
        width: 100%;
    }

    .WrapperArea .WrapperBox {
        margin-top: 40px;
    }

    .SearchBox a {
            padding: 0px 10px;
    }

    .SearchBox form input, .SearchBox form select {
        min-width: 120px;
    }

    .GaragesBox .GaragesHead figure{
        width: 100%;
        height: 100%;
    }

    .GaragesHead {
        flex-direction: column;
    }

    .GaragesBox .GaragesHead figcaption {
        width: 100%;
        padding: 10px 0 0 0;
    }

    .GaragesBox .GaragesHead figcaption h5 {
        display: inline-block;
    }

    .GaragesBox .QuoteBox {
        display: flex;
        flex-direction: column;
    }

    .GaragesBox .QuoteBox blockquote aside {
        width: 40%;
    }

    .GaragesBox .QuoteBox blockquote article {
        width: 60%;
    }

    .GaragesBox .QuoteBox blockquote article ul li label .Times input {
        width: 100%;
        min-width: 100%;
        font-size: 13px;
    }

    .openBtnSidebar img {
        height: 50px;
    }

    .mvpCloseSidebarBtn{
        display: block;
    }

    .mvpMenuBar{
        display: block;
    }

    .SideNavBar {
        display: none;
    }

    .DealerDetails .PartBox ul {
        gap:10%;
    }

    .DealerDetails .DealerSlider .carousel-item{
        height: 100%;
    }

    .GaragesBox .GaragesHead figcaption article aside a {
        margin: 5px;
        padding: 10px;
        float: right;
    }


    /*Garage*/
    .SearchBox form {
        flex-direction: column;
        gap: 15px;
    }

    .fc .fc-toolbar{
        flex-direction: column;
    }

    .DealerDetails .PartBox h3 {
        font-size: 20px;
    }

    /*Dealer*/

    .PartBox_ul_li{
        gap: 60% !important;
    }


}

.salesDashboardMapContainer{
    gap: 10px;
}

.scroller.my-vehicle{
    height: 150px;
    width: 150px;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    border-radius: 5px;
}
.scroller.my-vehicle > div{
    scroll-snap-align: center;
}
.scroller.my-vehicle img {
    height: 150px;
    min-width: 150px;
    aspect-ratio: 1/1;
    object-fit: cover;
}
.scroller-arrows{
    display: flex;
    justify-content: space-evenly;
    color: black;
    padding: 0 30px;
    width: 150px;
}

.scroller-arrows span{
    color: black !important;
    cursor: pointer;
}

.my-vehicle-info-container{
    gap: 10px;
}

.hover:hover .hover-d-flex{
    /* display: flex !important; */
}