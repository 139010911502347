
.verify-page-container{
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.verify-page-result, .verify-page-error{
    font-size: 24px;
    font-weight: 700;
}

.verify-page-error{
    color: brown;
}